import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TimeTokenLogo from "../TimeTokenLogo";
import TicketsCostIcon from "../TicketsCostIcon";
import "./Game.css";

import Transaction from "../Transaction";

import Wallet from "../../interface";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function Game(props) {

  const [_connected, _isConnected] = useState(false);
  const [_timeLeft, _getTimeLeft] = useState(0);
  const [_tokenLeft, _getTokenLeft] = useState(0);
  const [_lostTimes, setLostTimes] = useState(0);

  const [activeTransaction, showTransaction] = useState(false)
  const emitTransaction = async () => { await guessLetter() /*if (await guessLetter()) { showTransaction(true); }  */ }
  const hideTransaction = () => activeTransaction ? showTransaction(false) : null;

  const [transactionTitle, setTransactionTitle] = useState("Wait for the transaction:")
  const [transactionDescription, setTransactionDescription] = useState("0x000")

  const [_guessId, setGuessId] = useState(0);
  const [_ticketCost, setTicketCost] = useState("?");

  const [_letter, setLetter] = useState("");
  const [_guessed, setGuessed] = useState("? _ _ _ _ _ _ _ _ _ _ ?");

  let { id } = useParams();

  useEffect(async () => {
    if (localStorage.getItem("_gift_guess_2") == null) { 
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Connect Wallet',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("Connect Wallet"); 
      location.href = "/"; 
    }
    else {
      Wallet.web3 = localStorage.getItem("_gift_guess_2"); await Wallet.fetchAccountData();

      _isConnected(true);

      if (id != 0) {
        try{await getRandomGuess(id);}catch(e){}
        setGuessId(id);
      }
      else {
        if (localStorage.getItem("id~")) {
          setGuessId(localStorage.getItem("id~"))
          setTicketCost(await getGuessTicketCost(localStorage.getItem("id~")))
          setLostTimes(await getGuessLost())
          window.history.pushState('game', 'Game', '/game/' + id)
          let _guessed = await Wallet.guessContract().methods.getGuessed().call(
            { "from": Wallet.address, "to": Wallet.guessAddress() }
          )
          setGuessed(_guessed.length !== 0 ? _guessed : "? _ _ _ _ _ _ _ _ _ _ ?")
        }
      }
      await emitScreenConnection()
    }
  }, []);

  let _emit = false;
  let _time = 0;
  let _token = 0;

  const control = _ => {
    let _r = true;
    if (_time == 0 && _token == 0) {

      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Buy Time',
        title: 'Buy some time first to swap for tickets',
        showConfirmButton: true,
        timer: 5000
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.location.href = "/buy"
        }
        else { _r = false;}
      });

      //window.confirm("Buy some time first to swap for tickets") ? window.location.href = "/buy" : _r = false;
    }
    else if (_time == 0) {

      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Buy Time',
        title: 'Buy some time before playing the game',
        showConfirmButton: true,
        timer: 5000
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.location.href = "/buy"
        }
        else { _r = false;}
      });

      //window.confirm("Buy some time before playing the game") ? window.location.href = "/buy" : _r = false;
    }
    else if (_token == 0) {

      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Swap Time',
        title: 'Swap some time for tickets and start guessing to win',
        showConfirmButton: true,
        timer: 5000
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.location.href = "/swap"
        }
        else { _r = false;}
      });

      //window.confirm("Swap some time for tickets and start guessing to win") ? window.location.href = "/swap" : _r = false;
    }
    return _r;
  }

  const emitScreenConnection = async () => {
    try{_time = await Wallet.theTimeLeft();}catch(e){}

    let s = _time % 60;
    let m = parseInt(_time / 60) % 60;
    let h = parseInt(parseInt(_time / 60) / 60) % 24;

    if (s.toString().length < 2) { s = "0" + s; }
    if (m.toString().length < 2) { m = "0" + m; }
    if (h.toString().length < 2) { h = "0" + h; }

    //_getTimeLeft((_time == null || _time == 0) ? "NO TIME" : (h == '00' ? '' : (h + ":")) + m + ":" + s);

    try{_token = await Wallet.theTokenLeft();}catch(e){}

    _getTokenLeft(_token == null ? 0 : _token);

    if (!_emit) { control(); _emit = true; }


    //await sleep(15000);

    let _t = 15;
    while(_t-->0 && _time >= 0){
      await sleep(1000);
      _getTimeLeft((_time == null || _time == 0) ? "NO TIME" : (h == '00' ? '' : (h + ":")) + m + ":" + s);
      _time -= 1;

      s = _time % 60;
      m = parseInt(_time / 60) % 60;
      h = parseInt(parseInt(_time / 60) / 60) % 24;

      if (s.toString().length < 2) { s = "0" + s; }
      if (m.toString().length < 2) { m = "0" + m; }
      if (h.toString().length < 2) { h = "0" + h; }
      
    }
    
    await emitScreenConnection();
  }

  

  const getRandomGuess = async (id) => {
    if (id) {
      //window.alert("Sign the transaction to fetch the Guess");

      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Sign the transaction to fetch the Guess',
        showConfirmButton: false,
        timer: 5000
      })

      await Wallet.guessContract().methods.setGuess(id)
        .send({ "from": Wallet.address, "to": Wallet.guessAddress(), "gasPrice": "40000000000" });
    }
    else {
      try{
        let transaction = await Wallet.guessContract().methods.setRandomGuess()
          .send({ "from": Wallet.address, "to": Wallet.guessAddress(), "gasPrice": "40000000000" /*, "gasLimit": "8000000", "gas": "6000000"*/ });

        var receipt = await Wallet.web3.eth.getTransactionReceipt(transaction.transactionHash, function (e, r) {
          console.log("e", e);
          console.log("r", r);
        })

        id = await Wallet.guessContract().methods.getGuess().call({ "from": Wallet.address, "to": Wallet.guessContract() });
      }
      catch(e){ 
        //window.alert("Something went wrong... Roll the dice again"); 
          
        window.Swal.fire({
          position: 'top-end',
          //icon: 'error',
          title: 'Something went wrong... Roll the dice again',
          showConfirmButton: false,
          timer: 5000
        })
        return;
      }
    }

    localStorage.setItem("id~", id);
    setGuessId(id);

    window.history.pushState('game', 'Game', '/game/' + id);

    setTicketCost(await getGuessTicketCost(id));

    let _guessed = await Wallet.guessContract().methods.getGuessed().call(
      { "from": Wallet.address, "to": Wallet.guessAddress() }
    );
    setGuessed(_guessed.length !== 0 ? _guessed : "? _ _ _ _ _ _ _ _ _ _ ?");

    setLostTimes(await getGuessLost());
  }

  let val;
  const updateLetter = (evt) => {
    val = evt.target.value;
    setLetter(val.toLowerCase());
  }

  const guessLetter = async () => {

    let _r = false;

    if (_timeLeft == "NO TIME" && _tokenLeft == 0) { 
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Buy Time',
        title: 'Buy some time and tickets',
        showConfirmButton: true,
        timer: 5000
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.location.href = "/buy"
        }
        else { _r = true;}
      });
      //window.confirm("Buy some time and tickets") ? window.location.href = "/buy" : _r = true; }
    }
    else if (_timeLeft == "NO TIME") { 
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Buy Time',
        title: 'Out of time',
        showConfirmButton: true,
        timer: 5000
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.location.href = "/buy"
        }
        else { _r = true;}
      });
      //window.confirm("Out of time") ? window.location.href = "/buy" : _r = true; 
    }
    else if (_tokenLeft == 0) { 
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Swap Time',
        title: 'Swap Time for Tickets',
        showConfirmButton: true,
        timer: 5000
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.location.href = "/swap"
        }
        else { _r = true;}
      });
      //window.confirm("No more tickets") ? window.location.href = "/swap" : _r = true; 
    //}
      
    }

    if (_letter.length == 0 && _guessId === 0) { 
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Fetch a random Guess',
        title: 'Before guessing, roll the dice and then type in a letter or number',
        showConfirmButton: true,
        timer: 5000
      }).then(async (result) => {
        if (result.isConfirmed) {
          await getRandomGuess(null)
        }
        else { _r = true;}
      });
      //window.confirm("Before guessing, roll the dice and then type in a letter or number") ? await getRandomGuess(null) : _r = true; 
    }
    else if (_letter.length == 0) { 
      

      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Type in a letter or number',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("Type in a letter or number"); 
      
      return false; 
    }
    else if (_guessId == 0) { 
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Fetch a random Guess',
        title: 'Roll the dice',
        showConfirmButton: true,
        timer: 5000
      }).then(async (result) => {
        if (result.isConfirmed) {
          await getRandomGuess(null)
        }
        else { _r = true;}
      });

      //window.confirm("Roll the dice") ? await getRandomGuess(null) : _r = true; 
    }

    if (_letter.length > 1) { 
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Guess a single letter or number at a time',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("Guess a single letter or number at a time"); 
      return false; 
    }

    if (_r) return false;

    if(_ticketCost != 0 && _ticketCost > await new Wallet.web3.eth.Contract(require("../../data/abi/Erc20.json"), Wallet.ticketAddress(), {
      from: Wallet.address, // default from address
    }).methods.allowance(Wallet.address, Wallet.guessAddress()).call({ from: Wallet.address }))
    {
      try {
        await new Wallet.web3.eth.Contract(require("../../data/abi/Erc20.json"), Wallet.ticketAddress(), {
          from: Wallet.address, // default from xaddress
        }).methods.approve(Wallet.guessAddress(), _ticketCost).send({ from: Wallet.address });
      }catch (e){
        window.Swal.fire({
          position: 'top-end',
          //icon: 'error',
          title: 'Approve transaction to allow the contract transfer the assets in game',
          showConfirmButton: false,
          timer: 5000
        })
        //window.alert("Approve transaction to allow the contract transfer the assets in game");
        return false;
      }
    }


    let transaction = await Wallet.guessContract().methods.guessLetter(_letter)
      .send({ "from": Wallet.address, "to": Wallet.guessAddress() })

    setTransactionDescription(transaction.transactionHash);

    var receipt = await Wallet.web3.eth.getTransactionReceipt(transaction.transactionHash, function (e, r) {
      console.log("e", e);
      console.log("r", r);
    })

    let _guessed = await Wallet.guessContract().methods.getGuessed().call(
      { "from": Wallet.address, "to": Wallet.guessAddress() }
    );
    setGuessed(_guessed.length !== 0 ? _guessed : "? _ _ _ _ _ _ _ _ _ _ ?");
    let state = _guessed ? true : false;

    setLostTimes(await getGuessLost());

    if (_lostTimes > 7) { 
      

      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'You Lost',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("You Lost"); 
      localStorage.removeItem("id~"); window.location.href = "/"; /* getRandomGuess(null); */ }

    let win = await Wallet.guessContract().methods.guessed().call(
      { "from": Wallet.address, "to": Wallet.guessAddress() });

    if (win) {

      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'You Win',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("You Win");
      let claim = await Wallet.guessContract().methods.claim()
        .send({ "from": Wallet.address, "to": Wallet.guessAddress() })

      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Yey Claimed',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("Yey Claimed");
      
      localStorage.removeItem("id~");
      window.location.href = "/";
      //getRandomGuess(null);
    }

    return state;
  }

  const getGuessTicketCost = async (_id) => {
    return await Wallet.guessContract().methods.getGuessTicketCost(_id).call(
      { "from": Wallet.address, "to": Wallet.guessAddress() });
  }

  const getGuessLost = async () => {
    return await Wallet.guessContract().methods.getGuessLost().call(
      { "from": Wallet.address, "to": Wallet.guessAddress() });
  }


  const {
    frameWordGuessed,
    frameGuess,
    inputType,
    inputPlaceholder,
    overlapGroup3,
    iconAwesomeDice1,
    info,
    overlapGroup1,
    path12,
    path13,
    path14,
    path15,
    path16,
    path17,
    path18,
    path19,
    iconAwesomeDice,
    guessBtn,
    giftaguessBtn,
    iconAwesomeDrawPolygon,
    closeGame,
    timeTokenLogoProps,
    ticketsCostIconProps,
  } = props;

  return (
    <div className="game screen" onClick={hideTransaction}>

      {activeTransaction ? <Transaction title={transactionTitle} transactionDescription={transactionDescription} /> : null}

      <div className="window-game">
        <div className="overlap-group5">
          <img className="frame-word-guessed" src={"/img/frame.jpg"} />
          <img className="frame-guess" src={"/img/frame.jpg"} />
          <div className="word-letters-guessed helveticaneue-condensed-black-gravel-32px">{_guessed}</div>
          <textarea
            className="letter-guessed"
            name="letter-guessed4"
            placeholder={inputPlaceholder}
            type={inputType}
            required
            onKeyDown={(e)=>{if(e.key === "Enter"){document.activeElement.blur(); emitTransaction()}}}
            onChange={evt => updateLetter(evt)}
          ></textarea>
          <div className="overlap-group3"> {/*style={{ backgroundImage: `url(${overlapGroup3})` }}>*/}
            <img className="icon-awesome-dice-1" src={"/img/dice.jpg"} onClick={async () => { await getRandomGuess(null); }} />
          </div>
        </div>
        <Link to={`/info/${_guessId}`}>
          <img className="info" src={info} />
        </Link>
        <div className="guess-btn">
          <div className="overlap-group1-1">
           {/*<div className="ellipse-2-3"></div>
            <img className="path-12-3" src={path12} />
            <img className="path-13-3" src={path13} />
            <img className="path-14-3" src={path14} />
            <img className="path-15-3" src={path15} />
            <img className="path-16-3" src={path16} />
            <img className="path-17-3" src={path17} />
            <img className="path-18-3" src={path18} />
            <img className="path-19-3" src={path19} />*/}
          <Link to="/swap">
            <img className="icon-awesome-dice-0" src={"/img/ticket_logo_0.jpg"} />
          </Link>
          </div>
          <div className="guess-container-3">
           {/*<a href={`game#${transactionDescription}`} onClick={emitTransaction} >
            </a>*/}
            <div onClick={emitTransaction} >
              <img className="guess-btn-1 push_btn" src={"/img/push_btn.png"} />
            </div>
            <div className="guess-cost helveticaneue-condensed-black-dove-gray-62px">{_ticketCost}</div>
          </div>
        </div>
        <div className="overlap-group2z">
          <div className="b_0" style={{ display: (_lostTimes > 0) ? "flex" : "none" }}></div>
          <div className="b_1" style={{ display: (_lostTimes > 1) ? "flex" : "none" }}>
            <div className="rectangle-4"></div>
          </div>
          <div className="b_2" style={{ display: (_lostTimes > 2) ? "flex" : "none" }}></div>
          <div className="b_3" style={{ display: (_lostTimes > 3) ? "flex" : "none" }}>
            <div className="rectangle-6"></div>
          </div>
          <div className="b_4" style={{ display: (_lostTimes > 4) ? "flex" : "none" }}>
            <div className="rectangle-7"></div>
            <div className="ellipse-1"></div>
          </div>
          <div className="b_5" style={{ display: (_lostTimes > 5) ? "flex" : "none" }}>
            <div className="rectangle-8"></div>
          </div>
          <div className="b_7" style={{ display: (_lostTimes > 6) ? "flex" : "none" }}>
            <div className="overlap-group1-2" style={{ display: (_lostTimes > 7) ? "flex" : "none" }}>
              <div className="rectangle"></div>
              <div className="rectangle-1"></div>
            </div>
            <div className="overlap-group-3" style={{ display: (_lostTimes > 6) ? "flex" : "none" }}>
              <div className="rectangle"></div>
              <div className="rectangle-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="layer-white-bottom"></div>
        <div className="stats-game">
          <Link to="/buy">
              <div className="time-stats-frame">
                <TimeTokenLogo path14={timeTokenLogoProps.path14} />
                <div className="time-left-1 helveticaneue-condensed-black-dove-gray-54px">{_timeLeft}</div>
              </div>
          </Link>
          <Link to="/swap">
              <div className="time-stats-frame">
                <TicketsCostIcon path14={ticketsCostIconProps.path12} />
                <div className="tokens-left helveticaneue-condensed-black-dove-gray-62px">{_tokenLeft}</div>
              </div>
          </Link>
        </div>
      <Link to="/giftaguess/token/0/0x0000">
        <div className="giftaguess-btn" style={{ backgroundImage: `url(${"/img/gift.jpg"})` }}>
          {/*<img className="icon-awesome-draw-polygon" src={iconAwesomeDrawPolygon} />*/}
        </div>
      </Link>
      <Link to="/">
        <img className="close-game" src={closeGame} />
      </Link>
    </div>
  );
}


export default Game;
