import React from "react";
import "./Transaction.css";

function Transaction(props) {
  const { title, transactionDescription } = props;

  return (
    <div className="transaction screen">
      <div className="transaction-bubble">
        <div className="transaction-container">
          <div className="transaction-info-frame">
            <div className="transaction-frame-frame border-5px-dove-gray helveticaneue-condensed-black-gravel-32px">
              <div className="transaction-title">{title}</div>
              <div className="transaction-description"><a style={{color: "var(--gravel)"}} target="_blank" href={`https://mumbai.polygonscan.com/tx/${transactionDescription}`}>Click to view transaction</a></div>
            </div>
          </div>
          <img className="close-transaction" src="/img/close-create@1x.png" />
        </div>
      </div>
    </div>
  );
}

export default Transaction;
