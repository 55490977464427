import React from "react";
import { Link } from "react-router-dom";
import "./Buy.css";

import Transaction from "../Transaction";

import Wallet from "../../interface";

function Buy(props) {

  React.useEffect(async () => {
    if (localStorage.getItem("_gift_guess_2") == null) { 
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Connect Wallet',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("Connect Wallet"); 
      location.href = "/"; 
    }
    else {
      Wallet.web3 = localStorage.getItem("_gift_guess_2"); await Wallet.fetchAccountData();
    }
  }, []);

  const [_timeLeft, _getTimeLeft] = React.useState(0);
  const [_amount, setAmount] = React.useState(0);
  const [_timeBuy, _setTimeBuy] = React.useState("NO TIME");

  let val = 0;
  const updateAmount = (evt) => {
    val = evt.target.value;
    val = val * 10000;

    let _time = val;
    let s = _time % 60;
    let m = parseInt(_time / 60) % 60;
    let h = parseInt(parseInt(_time / 60) / 60) % 60;

    if (s.toString().length < 2) { s = "0" + s; }
    if (m.toString().length < 2) { m = "0" + m; }
    if (h.toString().length < 2) { h = "0" + h; }

    _setTimeBuy((_time == null || _time == 0) ? "NO TIME" : h + ":" + m + ":" + s);
    setAmount(val);
  }


  const buyTimeFor = async () => {

    let amount = (_amount / 10000);

    if (amount < 0.1) {
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Amount too small. Minimum 0.1 MATIC',
        showConfirmButton: false,
        timer: 5000
      })
      //alert("Amount too small. Minimum 0.1 MATIC"); 
    
    return false;}

    const transactionParameters = {
      nonce: '1', // ignored by MetaMask
      gasPrice: '3000000000', // customizable by user during MetaMask confirmation.
      gas: '0x2710', // customizable by user during MetaMask confirmation.
      to: Wallet.gameXAddress(),// Required except during contract publications.
      from: Wallet.address, // must match user's active address.
    };

    // txHash is a hex string
    // As with any RPC call, it may throw an error
    const txHash = await Wallet.gameXContract().methods.buyTimeFor().send({
      method: 'requestAccounts',
      params: [transactionParameters],
      value: Wallet.web3.utils.toWei(Number(amount).toPrecision(1), "ether"),
    });

    window.location.href = "/";

    return txHash;
  }

  const [activeTransaction, showTransaction] = React.useState(false)
  const emitTransaction = () => { buyTimeFor(); /*showTransaction(true)*/ }
  const hideTransaction = () => activeTransaction ? showTransaction(false) : null;

  const {
    closeBuy,
    path12,
    path13,
    path14,
    path15,
    path16,
    path17,
    path18,
    path19,
    iconAwesomeDice0,
    inputType1,
    inputPlaceholder1,
    inputType2,
    inputPlaceholder2,
    buyBtn,
  } = props;

  return (
    <div className="buy screen" onClick={hideTransaction}>
      {activeTransaction ? <Transaction /> : null}
      <div className="buy-boxed">
        {/*<Link to="/">*/}
        <div onClick={()=>window.history.back()}>
          <img className="close-buy" src={closeBuy} />
        </div>
       {/*</Link>*/}
      </div>
      <div className="frame-frame-buy">
        <div className="flex-row-1 border-5px-dove-gray">
          <div className="flex-col">
            <div className="place helveticaneue-condensed-black-gravel-32px">{"MATIC"}</div>
            <div className="overlap-group-2">
             {/*<div className="ellipse-2-2"></div>
              <img className="path-12-2" src={path12} />
              <img className="path-13-2" src={path13} />
              <img className="path-14-2" src={path14} />
              <img className="path-15-2" src={path15} />
              <img className="path-16-2" src={path16} />
              <img className="path-17-2" src={path17} />
              <img className="path-18-2" src={path18} />
              <img className="path-19-2" src={path19} />*/}
              <img className="icon-awesome-dice-0" src={"/img/time_logo_0.jpg"} />
            </div>
          </div>
          <div className="to-container">
            <textarea
              className="coin-amount-to-pay helveticaneue-condensed-black-dove-gray-62px"
              name="coin-amount-to-pay1"
              placeholder={"0"}
              type={"number"}
              step={"any"}
              required
              onKeyDown={(e)=>{if(e.key === "Enter"){document.activeElement.blur();emitTransaction()}}}
              onChange={evt => updateAmount(evt)}
            ></textarea>
            <div
              className="time-to-buy helveticaneue-condensed-black-dove-gray-54px"
              name="time-to-buy3"
              placeholder={inputPlaceholder2}
              type={inputType2}
              required
            >{_timeBuy}</div>
          </div>
        </div>
        <a href="buy#transaction" onClick={emitTransaction} >
          <img className="buy-btn push_btn" src={"/img/push_btn.png"} />
        </a>
      </div>
    </div>
  );
}

export default Buy;
