import React from "react";
import "./TicketsCostIcon.css";

function TicketsCostIcon(props) {
  const { path12 } = props;

  return (
    <div className="tickets-cost-icon-1">
      <div className="overlap-group-6">
        {/*<div className="ellipse-2-5"></div>
        <img className="path-12-5" src={path12} />
        <img className="path-13-5" src="/img/path-13-10@1x.png" />
        <img className="path-14-5" src="/img/path-14-10@1x.png" />
        <img className="path-15-5" src="/img/path-15-10@1x.png" />
        <img className="path-16-5" src="/img/path-16-10@1x.png" />
        <img className="path-17-5" src="/img/path-17-10@1x.png" />
        <img className="path-18-5" src="/img/path-18-10@1x.png" />
        <img className="path-19-5" src="/img/path-19-10@1x.png" />*/}
        <img style={{marginTop: "20px"}} className="icon-awesome-dice-0" src="/img/ticket_logo_0.jpg" />
      </div>
    </div>
  );
}

export default TicketsCostIcon;
