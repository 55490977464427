import "./App.css";
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import Landing from "./components/Landing";
import Buy from "./components/Buy";
import Game from "./components/Game";
import Giftaguess from "./components/Giftaguess";
import Nfts from "./components/Nfts";
import Tokens from "./components/Tokens";
import Create from "./components/Create";
import Swap from "./components/Swap";
import Info from "./components/Info";
import Transaction from "./components/Transaction";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/buy"><Buy {...buyData} /></Route>
        <Route path="/game/:id"><Game {...gameData} /></Route>
        <Route path="/game"><Game {...gameData} /></Route>
        <Route path="/giftaguess/:type/:id/:address"><Giftaguess {...giftaguessData} /></Route>
        <Route path="/giftaguess"><Giftaguess {...giftaguessData} /></Route>
        <Route path="/tokens"><Tokens closeTokens="/img/close-buy@1x.png" loadingTokens="…" tokenFrameProps={tokensData.tokenFrameProps} /></Route>
        <Route path="/swap"><Swap {...swapData} /></Route>
        <Route path="/info/:id"><Info {...infoData} /></Route>
        <Route path="/transaction"><Transaction title="info" createDescription="…" closeTransaction="/img/close-create@1x.png" /></Route>
        <Route path="/nfts">
          <Nfts
            loadingNfts="…"
            createNftBtn="/img/create-nft-btn@1x.png"
            closeNfts="/img/close-buy@1x.png"
            nftInWalletProps={nftsData.nftInWalletProps}
          />
        </Route>
        <Route path="/create">
          <Create
            closeCreate="/img/close-buy@1x.png"
            nftAddImage="/img/attatch-nft-btn@1x.png"
            inputType1="text"
            inputPlaceholder1="NFT"
            inputType2="text"
            inputPlaceholder2="…"
            createBtn="/img/create-btn@1x.png"
          />
        </Route>
        <Route><Landing {...landingData} /></Route>
      </Switch>
    </Router>
  );
}

export default App;
const timeTokenLogoData = {
  path14: "/img/path-14-6@1x.png",
};

const ticketsCostIconData = {
  path12: "/img/path-12-10@1x.png",
};

const gameData = {
  frameWordGuessed: "/img/frame-word-guessed@1x.png",
  frameGuess: "/img/frame-guess@1x.png",
  wordLettersGuessed: "_ _ _ _ _ _ _ _ _ _ _ _ _ _",
  inputType: "text",
  inputPlaceholder: "_",
  overlapGroup3: "/img/polygon-1@1x.png",
  iconAwesomeDice1: "/img/icon-awesome-dice-1-1-1@1x.png",
  info: "/img/info@1x.png",
  overlapGroup1: "/img/icon-material-sort-by-alpha-1@1x.png",
  path12: "/img/path-12-10@1x.png",
  path13: "/img/path-13-10@1x.png",
  path14: "/img/path-14-10@1x.png",
  path15: "/img/path-15-10@1x.png",
  path16: "/img/path-16-10@1x.png",
  path17: "/img/path-17-10@1x.png",
  path18: "/img/path-18-10@1x.png",
  path19: "/img/path-19-10@1x.png",
  iconAwesomeDice: "/img/icon-awesome-dice-2@1x.png",
  guessBtn: "/img/create-btn@1x.png",
  guessCost: "1",
  timeLeft: "00:00:00",
  tokensLeft: "10",
  giftaguessBtn: "/img/icon-metro-gift@1x.png",
  iconAwesomeDrawPolygon: "/img/icon-awesome-draw-polygon@1x.png",
  closeGame: "/img/close-buy@1x.png",
  timeTokenLogoProps: timeTokenLogoData,
  ticketsCostIconProps: ticketsCostIconData,
};
const nftInWallet2Data = {
  className: "nft-in-wallet-1",
};
const nftsData = {
  nftInWalletProps: nftInWallet2Data,
};
const tokenFrame2Data = {
  className: "token-frame-1",
};
const tokensData = {
  tokenFrameProps: tokenFrame2Data,
};
const landingData = {
  loading: "…",
  frameBottomGuess1: "/img/frame.jpg",
  overlapGroup13: "/img/icon-material-sort-by-alpha-6@1x.png",
  path121: "/img/path-12-11@1x.png",
  path131: "/img/path-13-10-1@1x.png",
  path141: "/img/path-14-10-1@1x.png",
  path151: "/img/path-15-10-1@1x.png",
  path161: "/img/path-16-10-1@1x.png",
  path171: "/img/path-17-10-1@1x.png",
  path181: "/img/path-18-10-1@1x.png",
  path191: "/img/path-19-10-1@1x.png",
  iconAwesomeDice1: "/img/icon-awesome-dice-7@1x.png",
  ticketAmountGuess1: "1",
  overlapGroup11: "/img/frame.jpg",
  nftImageGuess1: "/img/attatch-nft-btn@1x.png",
  nftAddressGuess1: "0x00000000000",
  nftIdGuess1: "0",
  nftTitleGuess1: "NFT",
  nftDescriptionGuess1: "…",
  guessContainer1: "/img/frame.jpg",
  tokenFrameGuess1: "/img/frame.jpg",
  tokenImageRewardGuess1: "/img/attatch-token-btn@1x.png",
  tokenAddressRewardGuess1: "0x00000000000",
  tokenAmountRewardGuess1: "0.00000000",
  tokenNameRewardGuess1: "COIN",
  frameBottomGuess2: "/img/frame.jpg",
  overlapGroup2: "/img/icon-material-sort-by-alpha-6@1x.png",
  path122: "/img/path-12-11@1x.png",
  path132: "/img/path-13-10-1@1x.png",
  path142: "/img/path-14-10-1@1x.png",
  path152: "/img/path-15-10-1@1x.png",
  path162: "/img/path-16-10-1@1x.png",
  path172: "/img/path-17-10-1@1x.png",
  path182: "/img/path-18-10-1@1x.png",
  path192: "/img/path-19-10-1@1x.png",
  iconAwesomeDice2: "/img/icon-awesome-dice-7@1x.png",
  ticketAmountGuess2: "1",
  overlapGroup12: "/img/frame.jpg",
  nftImageGuess2: "/img/attatch-nft-btn@1x.png",
  nftAddressGuess2: "0x00000000000",
  nftIdGuess2: "0",
  nftTitleGuess2: "NFT",
  nftDescriptionGuess2: "…",
  guessContainer2: "/img/frame.jpg",
  tokenFrameGuess2: "/img/frame.jpg",
  tokenImageRewardGuess2: "/img/attatch-token-btn@1x.png",
  tokenAddressRewardGuess2: "0x00000000000",
  tokenAmountRewardGuess2: "0.00000000",
  tokenNameRewardGuess2: "COIN",
  timeLeft: "00:00:00",
  buyTimeBtn: "/img/buy-time-btn@1x.png",
  path123: "/img/path-12-1@1x.png",
  path133: "/img/path-13-1@1x.png",
  path143: "/img/path-14-1@1x.png",
  path153: "/img/path-15-1@1x.png",
  path163: "/img/path-16-1@1x.png",
  path173: "/img/path-17-1@1x.png",
  path183: "/img/path-18-1@1x.png",
  path193: "/img/path-19-1@1x.png",
  iconAwesomeDice0: "/img/icon-awesome-dice-0-1-1@1x.png",
  iconAwesomeGift: "/img/icon-awesome-gift-1@1x.png",
  g: "G",
  text1: "$$",
  walletBtn: "/img/wallet-btn@1x.png",
  walletAddress: "0x0000",

  loading: "…",
  nftFrameFrameGuess: "/img/frame.jpg",
  nftAddressGuess: "0x00000000000",
  nftIdGuess: "0",
  nftTitleGuess: "NFT",
  nftDescriptionGuess: "…",
  nftImageGuess: "/img/nft-image-guess@1x.png",
  frameBottomGuess: "/img/frame.jpg",
  guessContainer: "/img/frame.jpgpng",
  tokenFrameGuess: "/img/frame.jpg",
  tokenImageRewardGuess: "/img/attatch-token-btn@1x.png",
  tokenAddressRewardGuess: "0x00000000000",
  tokenAmountRewardGuess: "0.00000000",
  tokenNameRewardGuess: "COIN",
  overlapGroup: "/img/icon-material-sort-by-alpha@1x.png",
  path12: "/img/path-12-1@1x.png",
  path13: "/img/path-13@1x.png",
  path14: "/img/path-14@1x.png",
  path15: "/img/path-15@1x.png",
  path16: "/img/path-16@1x.png",
  path17: "/img/path-17@1x.png",
  path18: "/img/path-18@1x.png",
  path19: "/img/path-19@1x.png",
  iconAwesomeDice: "/img/icon-awesome-dice@1x.png",
  ticketAmountGuess: "1",
  timeLeft: "00:00:00",
  buyTimeBtn: "/img/buy-time-btn@1x.png",
  iconAwesomeGift: "/img/icon-awesome-gift-1@1x.png",
  g: "G",
  text1: "$$",
  walletBtn: "/img/wallet-btn@1x.png",
  walletAddress: "0x0000",
};


const buyData = {
  closeBuy: "/img/close-buy@1x.png",
  place: "COIN",
  path12: "/img/path-12-4@1x.png",
  path13: "/img/path-13-1@1x.png",
  path14: "/img/path-14-6@1x.png",
  path15: "/img/path-15-1@1x.png",
  path16: "/img/path-16-4@1x.png",
  path17: "/img/path-17-1@1x.png",
  path18: "/img/path-18-4@1x.png",
  path19: "/img/path-19-1@1x.png",
  iconAwesomeDice0: "/img/icon-awesome-dice-0-1-1@1x.png",
  inputType1: "text",
  inputPlaceholder1: "0.0000",
  inputType2: "text",
  inputPlaceholder2: "00:00:00",
  buyBtn: "/img/buy-btn@1x.png",
};
const giftaguessData = {
  closeGiftaguess: "/img/close-buy@1x.png",
  frameAttatched: "/img/frame.jpg",
  attatchTokenBtn: "/img/attatch-token-btn@1x.png",
  attatchNftBtn: "/img/attatch-nft-btn@1x.png",
  inputType1: "text",
  inputPlaceholder1: "0x00000000000",
  inputType2: "text",
  inputPlaceholder2: "0.00000000000",
  inputType3: "text",
  inputPlaceholder3: "0x00000000000",
  inputType4: "text",
  inputPlaceholder4: "0",
  frameWordToGuess: "/img/frame.jpg",
  inputType5: "text",
  inputPlaceholder5: "_ _ _ _ _ _ _ _ _ _ _ _ _ _",
  overlapGroup11: "/img/icon-material-sort-by-alpha-1@1x.png",
  path121: "/img/path-12-10@1x.png",
  path131: "/img/path-13-10@1x.png",
  path141: "/img/path-14-10@1x.png",
  path151: "/img/path-15-10@1x.png",
  path161: "/img/path-16-10@1x.png",
  path171: "/img/path-17-10@1x.png",
  path181: "/img/path-18-8@1x.png",
  path191: "/img/path-19-10@1x.png",
  iconAwesomeDice1: "/img/icon-awesome-dice-4-1@1x.png",
  setGuessBtn: "/img/buy-btn@1x.png",
  inputType6: "text",
  inputPlaceholder6: "1",
  wordToGuess: "_ _ _ _ _ _ _ _ _ _ _ _ _ _",
  guessedCheck: "/img/guessed-check@1x.png",
  overlapGroup12: "/img/icon-material-sort-by-alpha-5@1x.png",
  path122: "/img/path-12-9@1x.png",
  path132: "/img/path-13-9@1x.png",
  path142: "/img/path-14-9@1x.png",
  path152: "/img/path-15-9@1x.png",
  path162: "/img/path-16-9@1x.png",
  path172: "/img/path-17-9@1x.png",
  path182: "/img/path-18-9@1x.png",
  path192: "/img/path-19-9@1x.png",
  iconAwesomeDice2: "/img/icon-awesome-dice-5-1@1x.png",
  ticketsCollecteds: "0",
  disableGuess: "/img/disable-guess@1x.png",
  postedNext: "/img/posted-next-1@1x.png",
};
const swapData = {
  closeSwap: "/img/close-buy@1x.png",
  path121: "/img/path-12-4@1x.png",
  path131: "/img/path-13-10-1-1@1x.png",
  path141: "/img/path-14-1@1x.png",
  path151: "/img/path-15-1@1x.png",
  path161: "/img/path-16-1@1x.png",
  path171: "/img/path-17-1@1x.png",
  path181: "/img/path-18-4@1x.png",
  path191: "/img/path-19-1@1x.png",
  iconAwesomeDice0: "/img/icon-awesome-dice-0-1-1@1x.png",
  inputType1: "text",
  inputPlaceholder1: "00:00:00",
  overlapGroup: "/img/icon-material-sort-by-alpha-1@1x.png",
  path122: "/img/path-12-10@1x.png",
  path132: "/img/path-13-10@1x.png",
  path142: "/img/path-14-10@1x.png",
  path152: "/img/path-15-10@1x.png",
  path162: "/img/path-16-10@1x.png",
  path172: "/img/path-17-10@1x.png",
  path182: "/img/path-18-8@1x.png",
  path192: "/img/path-19-10@1x.png",
  iconAwesomeDice: "/img/icon-awesome-dice-4-1@1x.png",
  inputType2: "text",
  inputPlaceholder2: "0",
  swapBtn: "/img/buy-btn@1x.png",
};
const infoData = {
  closeInfo: "/img/close-buy@1x.png",
  frameBottomGuess: "/img/frame.jpg",
  overlapGroup: "/img/icon-material-sort-by-alpha@1x.png",
  path12: "/img/path-12-11@1x.png",
  path13: "/img/path-13-10-1@1x.png",
  path14: "/img/path-14-10-1@1x.png",
  path15: "/img/path-15-10-1@1x.png",
  path16: "/img/path-16-10-1@1x.png",
  path17: "/img/path-17-10-1@1x.png",
  path18: "/img/path-18-10-1@1x.png",
  path19: "/img/path-19-10-1@1x.png",
  iconAwesomeDice: "/img/icon-awesome-dice-7@1x.png",
  ticketAmountGuess: "1",
  overlapGroup1: "/img/frame.jpg",
  nftImageGuess: "/img/attatch-nft-btn@1x.png",
  nftAddressGuess: "0x00000000000",
  nftIdGuess: "0",
  nftTitleGuess: "NFT",
  nftDescriptionGuess: "…",
  guessContainer: "/img/frame.jpg",
  tokenFrameGuess: "/img/frame.jpg",
  tokenImageRewardGuess: "/img/attatch-token-btn@1x.png",
  tokenAddressRewardGuess: "0x00000000000",
  tokenAmountRewardGuess: "0.00000000",
  tokenNameRewardGuess: "COIN",
  nftFrameFrameGuess: "/img/frame.jpg",

};

