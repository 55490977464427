import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./Giftaguess.css";

import OverflowScrolling from 'react-overflow-scrolling';

import Transaction from "../Transaction";

import Wallet from "../../interface";



function Giftaguess(props) {


  const [_wordsGifted, setWordsGifted] = useState([]);

  const [_timeLeft, _getTimeLeft] = useState(0);
  const [_timeBuy, _setTimeBuy] = useState("00:00:00");

  const [activeTransaction, showTransaction] = useState(false)
  const emitTransaction = () => { giftAGuess(); /*showTransaction(true)*/ }
  const hideTransaction = () => activeTransaction ? showTransaction(false) : null;

  const [_word, setWord] = useState(null)
  const updateWord = (e) => {
    setWord(e.target.value);
    localStorage.setItem("gag__0_word", e.target.value);
  }
  const [_guessCost, setGuessCost] = useState(null)
  const updateGuessCost = (e) => {
    if(e.target.value > 10 && e.target.value < 0){ 
      
    window.Swal.fire({
      position: 'top-end',
      //icon: 'error',
      title: 'Wrong letter Guess cost has to between 0 and 10',
      showConfirmButton: false,
      timer: 5000
    })

      //window.alert("Wrong letter Guess cost has to between 0 and 10"); 
      return;
    }
    setGuessCost(e.target.value);
    localStorage.setItem("gag__0_guessCost", e.target.value);
  }

  const [_tokenAddress, setTokenAddress] = useState(null)
  const updateTokenAddress = (e) => {
    setTokenAddress(e.target.value);
    localStorage.setItem("gag__0_tokenAddress", e.target.value);
  }

  const [_tokenAmount, setTokenAmount] = useState(null)
  const updateTokenAmount = (e) => {
    //if(Wallet.ticketAddress().toLowerCase() == address.toLowerCase()){decimals = 10**18;}

    setTokenAmount(e.target.value);
    localStorage.setItem("gag__0_tokenAmount", e.target.value);
  }

  const [_nftAddress, setNftAddress] = useState(null)
  const updateNftAddress = (e) => {
    setNftAddress(e.target.value);
    localStorage.setItem("gag__0_nftAddress", e.target.value);
  }
  const [_nftId, setNftId] = useState(null)
  const updateNftId = (e) => {
    setNftId(e.target.value);
    localStorage.setItem("gag__0_nftId", e.target.value);
  }


  const [_listStyleHeigh, _setListStyleHeigh] = useState(0);

  const [transactionTitle, setTransactionTitle] = useState("Wait for the transaction:")
  const [transactionDescription, setTransactionDescription] = useState("0x000")

  let { type, id, address } = useParams();

  let decimals = 1;//0**18;

  useEffect(async () => {
    if (localStorage.getItem("_gift_guess_2") == null) { 
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Connect Wallet',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("Connect Wallet"); 
      location.href = "/"; 
    }
    else {
      Wallet.web3 = localStorage.getItem("_gift_guess_2"); await Wallet.fetchAccountData();

      if (type == "nft" && id && address) {
        
        setNftId(id);
        setNftAddress(address);
        localStorage.getItem("gag__0_nftId") ? setNftId(id) : 0;
        localStorage.getItem("gag__0_nftAddress") ? setNftAddress(address) : null;
        localStorage.setItem("gag__0_nftId", id);
        localStorage.setItem("gag__0_nftAddress", address);
      }
      else {
        localStorage.getItem("gag__0_nftId") ? setNftId(localStorage.getItem("gag__0_nftId")) : 0;
        localStorage.getItem("gag__0_nftAddress") ? setNftAddress(localStorage.getItem("gag__0_nftAddress")) : null;
      }
      if (type == "token" && id && address) {

        if(Wallet.timeAddress().toLowerCase() == address.toLowerCase()){
          
          window.Swal.fire({
            position: 'top-end',
            //icon: 'error',
            title: 'Cannot attatch TIME!',
            showConfirmButton: false,
            timer: 5000
          })

          //window.alert("Cannot attatch TIME!"); 
          return;}

        //if(Wallet.ticketAddress().toLowerCase() == address.toLowerCase()){decimals = 10**18;}

        if(Wallet.ticketAddress().toLowerCase() != address.toLowerCase())
        {id = id / (10 ** 18);}

        //let decimals = 10**18;
          try{decimals = 10**(18-await new Wallet.web3.eth.Contract(require("../../data/abi/Erc20.json"), address, {
            from: Wallet.address,
            to: address,
        }).methods.decimals().call({ "from": Wallet.address, "to": address }));}catch(e){}

        id *= decimals;

        setTokenAmount(id);
        setTokenAddress(address);
        localStorage.getItem("gag__0_tokenAmount") ? setTokenAmount(id) : 0;
        localStorage.getItem("gag__0_tokenAddress") ? setTokenAddress(address) : "";
        localStorage.setItem("gag__0_tokenAmount", id);
        localStorage.setItem("gag__0_tokenAddress", address);
      }
      else {
        localStorage.getItem("gag__0_tokenAmount") ? setTokenAmount(localStorage.getItem("gag__0_tokenAmount")) : 0;
        localStorage.getItem("gag__0_tokenAddress") ? setTokenAddress(localStorage.getItem("gag__0_tokenAddress")) : "";
      }

      setWord(localStorage.getItem("gag__0_word"))
      setGuessCost(localStorage.getItem("gag__0_guessCost"))

      setWordsGifted(await wordsGifted());
    }
  }, []);

  let gifts = [];

  const wordsGifted = async () => {
    let _l = await Wallet.guessContract().methods.availableGuessCount().call({ "from": Wallet.address, "to": Wallet.guessAddress() });
    while (_l-- > 0) {
      let _available = await Wallet.guessContract().methods.isGuessAvailable(_l).call({ "from": Wallet.address, "to": Wallet.guessAddress() });

      if (Wallet.address == await Wallet.guessContract().methods.getGuessOwner(_l).call({ "from": Wallet.address, "to": Wallet.guessAddress() })) {
        gifts.push({
          available: _available,
          id: _l,
          word: await Wallet.guessContract().methods.getGuessWord(_l).call({ "from": Wallet.address, "to": Wallet.guessAddress() }),
          collected: await Wallet.guessContract().methods.getTicketsCollected(_l).call({ "from": Wallet.address, "to": Wallet.guessAddress() })
        });
      }
    }
    _setListStyleHeigh(_l * 200);

    return gifts;
  }

  const giftAGuess = async () => {
    
    if(Wallet.timeAddress().toLowerCase() == address.toLowerCase()){
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Cannot attatch TIME!',
        showConfirmButton: false,
        timer: 5000
      })
      //window.alert("Cannot attatch TIME!"); 
      return;}

      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Please sign the next 3 consecutive transactions to create your Guess and to allow the contract to hold your assets for winners',
        showConfirmButton: false,
        timer: 5000
      })

    //alert("Please sign the next 3 consecutive transactions to create your Guess and to allow the contract to hold your assets for winners");

    const transactionParameters = {
      nonce: '1', // ignored by MetaMask
      gasPrice: '3000000000', // customizable by user during MetaMask confirmation.
      gas: '0x2710', // customizable by user during MetaMask confirmation.
    };


    let erc20Addr = _tokenAddress;

    if(Wallet.ticketAddress().toLowerCase() == erc20Addr.toLowerCase()){decimals = 1;}
    else {
      decimals = (await new Wallet.web3.eth.Contract(require("../../data/abi/Erc20.json"), erc20Addr, {
        from: Wallet.address,
        to: erc20Addr,
      }).methods.decimals().call({ "from": Wallet.address, "to": erc20Addr }));
    }

    console.log("decimals", decimals)

    try{await new Wallet.web3.eth.Contract(require("../../data/abi/Erc20.json"), erc20Addr, {
       from: Wallet.address,
    }).methods.approve(Wallet.gameXAddress(), BigInt(Number(_tokenAmount * decimals))).send({ 
      method: 'requestAccounts',
      //params: [transactionParameters],
      from: Wallet.address, // default from address
      to: erc20Addr,
     });}catch(e){
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Cannot approve Token',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("Cannot approve Token"); 
      return;}

    //await sleep(3000);

    let erc721Addr = _nftAddress;
    try{await new Wallet.web3.eth.Contract(require("../../data/abi/Erc721.json"), erc721Addr, {
       from: Wallet.address,
    }).methods.approve(Wallet.gameXAddress(), BigInt(Number(_nftId))).send({ 
      method: 'requestAccounts',
      //params: [transactionParameters],
      from: Wallet.address, // default from address
      to: erc721Addr,
     });}catch(e){
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Cannot approve NFT',
        showConfirmButton: false,
        timer: 5000
      })
 
      //window.alert("Cannot approve NFT"); 
      return;}

    //await sleep(3000);

    //let _transactionFinal;
    //_transactionFinal = 
    try{await Wallet.gameXContract().methods
      .giftAGuess("ipfs://QmdPJZSV6iae6zDuLsJhmPP9cpSxxsQ4mmWYJaqg7zo4SQ", 
                  BigInt(Number(_guessCost)), 
                  BigInt(Number(_tokenAmount * decimals)), 
                  erc20Addr, _word, 
                  BigInt(Number(_nftId)), 
                  erc721Addr)
      .send({
        method: 'requestAccounts',
        //params: [transactionParameters],  
        from: Wallet.address,
        to: Wallet.gameXAddress(),
      });}catch(e){
        
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Transaction not processed',
        showConfirmButton: false,
        timer: 5000
      })

        //window.alert("Transaction not processed"); 
        return;}

    //setTransactionDescription(_transactionFinal.transactionHash);

    localStorage.removeItem("gag__0_word");
    localStorage.removeItem("gag__0_guessCost");

    localStorage.removeItem("gag__0_nftId");
    localStorage.removeItem("gag__0_nftAddress");
    localStorage.removeItem("gag__0_tokenAmount");
    localStorage.removeItem("gag__0_tokenAddress");


    window.Swal.fire({
      position: 'top-end',
      //icon: 'error',
      title: 'Successfully minted and attatched a Guess! Share the link with friends',
      showConfirmButton: false,
      timer: 5000
    })

    //window.alert("Successfully minted and attatched a Guess! Share the link with friends");

    window.location.href = "/giftaguess";
    //return _transactionFinal;
  }

  const retrieveGifted = async (_guessId) => {
    await Wallet.guessContract().methods.removeGuessWord(_guessId).send({ "from": Wallet.address, "to": Wallet.guessAddress() });
  }

  const {
    closeGiftaguess,
    frameAttatched,
    attatchTokenBtn,
    attatchNftBtn,
    inputType1,
    inputPlaceholder1,
    inputPlaceholder2,
    inputType3,
    inputPlaceholder3,
    inputType4,
    inputPlaceholder4,
    frameWordToGuess,
    inputType5,
    inputPlaceholder5,
    overlapGroup11,
    path121,
    path131,
    path141,
    path151,
    path161,
    path171,
    path181,
    path191,
    iconAwesomeDice1,
    setGuessBtn,
    inputType6,
    inputPlaceholder6,
    guessedCheck,
    overlapGroup12,
    path122,
    path132,
    path142,
    path152,
    path162,
    path172,
    path182,
    path192,
    iconAwesomeDice2,
    disableGuess,
    postedNext,
  } = props;

  return (
    <div className="container-center-horizontal" onClick={hideTransaction}>

      {activeTransaction ? <Transaction title={transactionTitle} transactionDescription={transactionDescription} /> : null}

      <div className="giftaguess screen">
        <Link to="/game/0">
        {/*<div onClick={()=>window.history.back()}>*/}
          <img className="close-giftaguess" src={closeGiftaguess} />
        {/*</div> </div>*/}
        </Link>
        <div className="attach-guess">
          <div className="overlap-group2-1">
            <img className="frame-attatched" src={frameAttatched} />
            <div className="attatched-2">
              <div className="attatch-container">
                <Link to="/tokens">
                  <img className="attatch-token-btn" src={"/img/tokens_btn.jpg"} />
                </Link>
                <Link to="/nfts">
                  <img className="attatch-nft-btn" src={"/img/camera_btn.jpg"} />
                </Link>
              </div>
              <div className="overlap-group-container">
                <div className="attatched-token-a-container">
                  <input
                    className="attatched helveticaneue-condensed-black-gravel-32px"
                    name="attatched-token-address1"
                    placeholder={inputPlaceholder1}
                    type={inputType1}
                    required
                    onKeyDown={(e)=>{if(e.key === "Enter"){document.activeElement.blur();}}}
                    onChange={e => updateTokenAddress(e)}
                    value={_tokenAddress}
                  />
                  <input
                    className="attatched-1 helveticaneue-condensed-black-gravel-32px"
                    name="attatched-token-amount2"
                    placeholder={inputPlaceholder2}
                    type={"number"}
                    step={"any"}
                    required
                    onKeyDown={(e)=>{if(e.key === "Enter"){document.activeElement.blur();}}}
                    onChange={e => updateTokenAmount(e)}
                    value={_tokenAmount}
                  />
                </div>
                <div className="attatched-nft-container">
                  <input
                    className="attatched helveticaneue-condensed-black-gravel-32px"
                    name="attatched-nft-address4"
                    placeholder={inputPlaceholder3}
                    type={inputType3}
                    required
                    onKeyDown={(e)=>{if(e.key === "Enter"){document.activeElement.blur();}}}
                    onChange={e => updateNftAddress(e)}
                    value={_nftAddress}
                  />
                  <input
                    className="attatched-1 helveticaneue-condensed-black-gravel-32px"
                    name="attatched-nft-id5"
                    placeholder={inputPlaceholder4}
                    type={"number"}
                    step={"any"}
                    required
                    onKeyDown={(e)=>{if(e.key === "Enter"){document.activeElement.blur();}}}
                    onChange={e => updateNftId(e)}
                    value={_nftId}
                  />
                </div>
              </div>
            </div>
            <img className="frame-word-to-guess" src={frameWordToGuess} />
            <textarea
              className="word-to-guess helveticaneue-condensed-black-gravel-32px"
              name="word-to-guess3"
              placeholder={inputPlaceholder5}
              type={inputType5}
              required
              value={_word}
              onKeyDown={(e)=>{if(e.key === "Enter"){document.activeElement.blur();}}}
              onChange={e => updateWord(e)}
            ></textarea>
          </div>
        </div>
        <div className="value">
          <div className="overlap-group1-4">
            <img className="icon-awesome-dice-0" src={"/img/ticket_logo_0.jpg"} />
          </div>
          <div className="guess-container-4">
            <div onClick={emitTransaction} >
              <img className="set-guess-btn push_btn" src={"/img/push_btn.png"} />
            </div>
            {/*<a href="giftaguess#transaction" onClick={emitTransaction} >
              <img className="set-guess-btn" src={setGuessBtn} />
            </a>*/}
            <textarea
              className="guess-cost-set helveticaneue-condensed-black-gravel-62px"
              name="guess-cost-set1"
              placeholder={inputPlaceholder6}
              type={"number"}
              step={"any"}
              required
              value={_guessCost}
              onKeyDown={(e)=>{if(e.key === "Enter"){document.activeElement.blur();}}}
              onChange={e => updateGuessCost(e)}
            ></textarea>
          </div>
        </div>
        <OverflowScrolling className='overflow-scrolling'>
          <div style={{height: "500px", overflowY: "sroll"}} >
            {_wordsGifted.map(({
              available,
              id,
              word,
              collected,
            }) => (
              <div className="posted" style={{ height: `${_listStyleHeigh}px`}} >
                <div className="guess-2 border-5px-dove-gray">
                  <div className="guess-container-5">
                    <div className="word-to-guess-1">{word}</div>
                    <img style={{ display: available ? "none" : "flex" }} className="guessed-check" src={guessedCheck} />
                  </div>
                  <div className="flex-row-2">
                    <div className="overlap-group1-5">
                      <div className="group-6">
                        <div className="overlap-group-5">
                          <img className="icon-awesome-dice-0" src={"/img/ticket_logo_0.jpg"} />
                        </div>
                      </div>
                    </div>
                    <div className="tickets-collecteds helveticaneue-condensed-black-dove-gray-32px">{collected}</div>
                    <img style={{ display: available ? "flex" : "none" }} className="disable-guess" src={disableGuess} onClick={() => retrieveGifted(id)} />
                  </div>
                </div>
                <Link to={`/game/${id}`} style={{ display: available ? "flex" : "none" }}>
                  <img className="posted-next" src={"/img/link_btn.png"} />
                </Link>
              </div>
            ))}
          </div>
        </OverflowScrolling>
      </div>
    </div>
  );
}

export default Giftaguess;
