import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TokenFrame from "../TokenFrame";
import "./Tokens.css";

import Wallet from "../../interface";

const fetch = require('node-fetch');

const HOST = process.env.HOST || "giftaguess.com"
const PORT = process.env.PORT || "";//":3006"

function Tokens(props) {

  const [_list, _setList] = useState([]);
  const [_listStyleHeigh, _setListStyleHeigh] = useState(0);

  let items = [];
  const [_item, _setItem] = useState([]);


  useEffect(async () => {
    if (localStorage.getItem("_gift_guess_2") == null) { 
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Connect Wallet',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("Connect Wallet"); 
      location.href = "/"; 
    }
    else {
      Wallet.web3 = localStorage.getItem("_gift_guess_2"); await Wallet.fetchAccountData();

      let response = await (await fetch('https://' + HOST + '' + PORT + '/tokenlist/' + Wallet.address)).json();
      _setList(response);

      response = Object.entries(response);

      let _i = 0
      for (let i = 0; i < response.length; i++) {
        for (let j = 0; j < response[i][1].list.length; j++) {
          if (response[i][1].list[j].id) {
            items.push({
              _key: _i,
              value: response[i][1].list[j].id,
              symbol: response[i][1].symbol,
              address: response[i][0],
            });
            _i++;
          }
        }
      }
      _setListStyleHeigh(300 + (_i) * 200);
      _setItem(items);
    }
  }, []);


  const { closeTokens } = props;

  return (
    <div className="container-center-horizontal">
      <div className="tokens screen" style={{ height: `${_listStyleHeigh}px` }} >
        <Link to="/giftaguess/token/0/0x0000">
          <img className="close-tokens" src={closeTokens} />
        </Link>
        <div className="loading-tokens helveticaneue-condensed-black-gravel-28px">{_item.length == 0 ? (<div class="loading_">Loading&#8230;</div>) : ""}</div>
        <div className="frame-tokens">
          {_item.map(({
            _key,
            value,
            symbol,
            address,
          }) => (
            <Link to={`/giftaguess/token/${value}/${address}`}>
              <TokenFrame key={_key + address} value={value / (10 ** 18)} address={address} symbol={symbol} /* title={title}  description={description}  img={img} */ />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Tokens;
