module.exports = {
    list: {
      png: { mime: 'image/png', type: 'Photo' },
      gif: { mime: 'image/gif', type: 'Animation' },
      jpg: { mime: 'image/jpeg', type: 'Photo' },
      pdf: { mime: 'application/pdf', type: 'Document' },
      doc: { mime: 'application/msword', type: 'Document' },
      ppt: { mime: 'application/vnd.ms-powerpoint', type: 'Document' },
      xls: { mime: 'application/vnd.ms-excel', type: 'Document' },
      acc: { mime: 'audio/acc', type: 'Audio' },
      aac: { mime: 'audio/x-aac', type: 'Audio' },
      mp4: { mime: 'video/mp4', type: 'Video' },
      '3gpp': { mime: 'video/3gpp', type: 'Video' },
      mp3: { mime: 'audio/mpeg', type: 'Audio' },
      amr: { mime: 'audio/amr', type: 'Audio' },
      opus: { mime: 'audio/ogg', type: 'Audio' },
      txt: { mime: 'text/plain', type: 'Document' },
      html: { mime: 'text/html', type: 'Document' },
      js: { mime: 'text/javascript', type: 'Document' },
      css: { mime: 'text/css', type: 'Document' },
      eot: { mime: 'application/vnd.ms-fontobject', type: 'Document' },
      svg: { mime: 'image/svg+xml', type: 'Photo' },
      ttf: { mime: 'font/ttf', type: 'Document' },
      woff: { mime: 'font/woff', type: 'Document' },
      woff2: { mime: 'font/woff2', type: 'Document' },
      tgz: { mime: 'application/gzip', type: 'Document' },
      json: { mime: 'application/json', type: 'Document' }
    },
    defaultNames: {
      photo: 'image.png',
      document: 'document.pdf',
      video: 'video.mp4',
      voice: 'voice.opus',
      audio: 'audio.mp3',
      sticker: 'sticker.gif',
      animation: 'sticker.gif'
    },
    get: {
      fromFileName: (fileName, mimeType) => {
        if (fileName) {
          fileName = fileName.split('.')
          if (fileName && fileName.length > 0) {
            fileName = fileName.slice(-1)
            if (fileName.length > 0 && fileName[0]) {
              fileName = fileName[0].toLowerCase()
              if (fileName) {
                fileName = fileName.replace('jpeg', 'jpg')
                if (fileName.substr(0, 7) == 'sf-part') {
                  fileName = 'tgz'
                }
              }
            }
          }
        }
        return fileName &&
          module.exports.list[fileName][mimeType ? mimeType : 'mime']
          ? module.exports.list[fileName][mimeType ? mimeType : 'mime']
          : null
      }
    }
  }