import React from "react";
import { Link } from "react-router-dom";
import "./Create.css";

import Transaction from "../Transaction";

import Wallet from "../../interface";

const mime = require("../../mime");

const formdata = require("form-data");
const fetch = require("node-fetch");

function Create(props) {

  React.useEffect(async () => {

    if (localStorage.getItem("_gift_guess_2") == null) { 
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Connect Wallet',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("Connect Wallet"); 
      location.href = "/"; 
    }
    else {
      Wallet.web3 = localStorage.getItem("_gift_guess_2"); await Wallet.fetchAccountData();
    }
  }, []);

  const [state, setState] = React.useState({

    // Initially, no file is selected
    selectedFile: null
  });

  const [file, setFile] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");

  const onFileChange = event => {

    let _file = event.target.files[0];

    // Update the state
    setState({ selectedFile: _file });

    _file ? setFile(true) : setFile(false);

    if(_file)
    {
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Attatched',
        showConfirmButton: false,
        timer: 5000
      })
    }
    else {
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Failed to attatch',
        showConfirmButton: false,
        timer: 5000
      })
    }

    //_file ? window.alert("Attatched") : window.alert("Failed to attatch");

  };

  // On file upload (click the upload button)
  const onFileUpload = async () => {

    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "myFile",
      state.selectedFile,
      state.selectedFile.name
    );

    let blob = new Blob([state.selectedFile], {
      type: formData.type
    });
    let fileUri = await ipfs.up("file.json", blob);

    let content = {
      description: description,
      external_url: "",
      image: "ipfs://"+fileUri,
      name: title
    };

    blob = new Blob([JSON.stringify(content)], {
      type: "application/json;charset=utf-8"
    });

    let mintUri = await ipfs.up("uri.json", blob);

    await Wallet.createContract().methods.safeMint(Wallet.address, mintUri)
      .send({
        from: Wallet.address,
        to: Wallet.createAddress()
      });

      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Successfully created an NFT',
        showConfirmButton: false,
        timer: 5000
      })

    //window.alert("Successfully created an NFT")
    window.location.href = "/nfts";
  };

  const ipfs = {
    up: async (name, data) => {
      const form = new formdata();
      form.append("file", data, {
        contentType: mime.get.fromFileName(name),
        filename: name.split("/").pop()
      });
      const serverResponse = await fetch(
        "https://ipfs.infura.io:5001/api/v0/add",
        {
          method: "POST",
          body: form
        }
      );
      try {
        const result = await serverResponse.json();
        return result && result.Hash ? result.Hash : null;
      } catch (e) { }
    }
  };

  const [activeTransaction, showTransaction] = React.useState(false)
  const emitTransaction = async () => { await onFileUpload(); /*showTransaction(true)*/ }
  const hideTransaction = () => activeTransaction ? showTransaction(false) : null;

  const { closeCreate, nftAddImage, inputType1, inputPlaceholder1, inputType2, inputPlaceholder2, createBtn } = props;

  return (
    <div className="container-center-horizontal" onClick={hideTransaction}>

      {activeTransaction ? <Transaction /> : null}

      <div className="create screen">
        <Link to="/nfts">
          <img className="close-create" src={closeCreate} />
        </Link>
        <div className="nft-frame-create">
          <div className="nft-frame-frame-create border-5px-dove-gray">
            <div style={{
              backgroundImage: `url(${"/img/camera_btn.jpg"})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat"
            }} >
              <input type="file" onChange={onFileChange} style={{ opacity: 0, width: "80px", height: "80px", cursor: "pointer" }} />
            </div>
            <div className="nft-container-1">
              <input
                className="nft-title helveticaneue-condensed-black-gravel-32px"
                name="nft-title1"
                placeholder={inputPlaceholder1}
                type={inputType1}
                required
                onChange={(e) => setTitle(e.target.value)}
              />
              <textarea
                className="nft-create-description helveticaneue-condensed-black-gravel-32px"
                name="nft-create-description3"
                placeholder={inputPlaceholder2}
                type={inputType2}
                required
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <a href="create#transaction" onClick={emitTransaction} >
          <img className="create-btn push_btn" src={"/img/push_btn.png"} />
        </a>
      </div>
    </div>
  );
}

export default Create;
