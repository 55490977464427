import React from "react";
import "./TimeTokenLogo.css";

function TimeTokenLogo(props) {
  const { path14 } = props;

  return (
    <div className="time-token-logo-1">
      <div className="overlap-group-4">
        {/*<div className="ellipse-2-4"></div>
        <img className="path-12-4" src="/img/path-12-1@1x.png" />
        <img className="path-13-4" src="/img/path-13-1@1x.png" />
        <img className="path-14-4" src={path14} />
        <img className="path-15-4" src="/img/path-15-1@1x.png" />
        <img className="path-16-4" src="/img/path-16-1@1x.png" />
        <img className="path-17-4" src="/img/path-17-1@1x.png" />
        <img className="path-18-4" src="/img/path-18-1@1x.png" />
        <img className="path-19-4" src="/img/path-19-1@1x.png" />*/}
        <img className="icon-awesome-dice-0" src="/img/time_logo_0.jpg" />
      </div>
    </div>
  );
}

export default TimeTokenLogo;
