import WalletLink from 'walletlink'
const Web3Modal = window.Web3Modal.default

const WalletConnectProvider = window.WalletConnectProvider.default

const Venly = window.Venly
const evmChains = window.evmChains

let Web3 = require('web3')

// Web3modal instance
let web3Modal = Web3Modal

// Chosen wallet provider given by the dialog window
let provider = false

// Address of the selected account
let selectedAccount

window.alert ? window.alert : alert
window.confirm ? window.confirm : confirm

let walletLink

try {
// Subscribe to accounts change
provider.on("accountsChanged", (accounts) => {
  Wallet.fetchAccountData();
});

// Subscribe to chainId change
provider.on("chainChanged", (chainId) => {
  Wallet.fetchAccountData();
});

// Subscribe to networkId change
provider.on("networkChanged", (networkId) => {
  Wallet.fetchAccountData();
});
} catch(e){}


export const Wallet = {
  web3: null,
  address: null,

  init: async () => {
    const providerOptions = {
      'custom-coinbase': {
        display: {
          logo: 'https://lh3.googleusercontent.com/3pwxYyiwivFCYflDJtyWDnJ3ZgYuN_wBQBHqCXbKh9tJTdTL1uOrY1VyxeC_yXLTNZk',
          name: 'Coinbase',
          description: 'Connect with Coinbase Wallet',
        },
        options: {
          appName: 'Gift A Guess', // Your app name
          networkUrl:
            'https://polygon-mainnet.infura.io/v3/bc387b3170744479a702cd6bb12f4289',
          chainId: 137,
        },
        package: WalletLink,
        connector: async (_, options) => {
          walletLink = new WalletLink({
            appName: 'Gift A Guess',
          })
          provider = walletLink.makeWeb3Provider(
            'https://polygon-mainnet.infura.io/v3/bc387b3170744479a702cd6bb12f4289',
            137
          )
          await provider.enable()

          Wallet.web3 = new Web3(provider)

          // Use eth_RequestAccounts
          provider.send(
            {
              gas: 200000,
              gasPrice: 2,
              method: 'eth_requestAccounts',
              params: [],
            },
            (accounts) => {
              console.log(`User's address is ${accounts[0]}`)

              // Optionally, have the default account set for web3.js
              Wallet.web3.eth.defaultAccount = accounts[0]
            }
          )

          // Alternatively, you can use ethereum.enable()
          provider.enable((accounts) => {
            console.log(`User's address is ${accounts[0]}`)
            Wallet.web3.eth.defaultAccount = accounts[0]
          })
        },
      },
      
      /*
      venly: {
        package: Venly,
        options: {
          //clientId: 'Testaccount-capsule',
          //secretId: '82c19251-1753-44f5-ae76-93438d3628de',
          //applicationId: '3b613051-4e10-479d-a75a-ee355541e5a0',
          //environment: 'staging',
          clientId: 'Testaccount',
          environment: 'staging',
        },
      },
      */

      walletconnect: {
        package: WalletConnectProvider,
        options: {
          appName: 'giftaguess',
          projectId: 'bc387b3170744479a702cd6bb12f4289',
          infuraId: 'bc387b3170744479a702cd6bb12f4289',
          rpc: {
            137:
              'https://polygon-mainnet.infura.io/v3/bc387b3170744479a702cd6bb12f4289',
          },
        },
      },
      
    }
    web3Modal = new Web3Modal({
      network: 'mainnet', // optional
      cacheProvider: false, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    })


    console.log("Opening a dialog", web3Modal);
    try {
      provider = await web3Modal.connect();
    } catch(e) {
      console.log("Could not get a wallet connection", e);
      return;
    }

    //provider = await web3Modal.connect()

    return provider ? true : false
  },

  /**
   * Kick in the UI action after Web3modal dialog has chosen a provider
   */
  fetchAccountData: async () => {

    //provider = localStorage.getItem("provider") != null ? localStorage.getItem("provider"): false;


    // Old web3 provider
    if (Wallet.web3 && provider) {
      Wallet.web3 = new Web3(provider);//(Wallet.web3.currentProvider)
      // no need to ask for permission
    }
    else
    if (window.ethereum){//} && !provider) {
      Wallet.web3 = new Web3(ethereum)
      try {
        // ask user for permission
        await ethereum.enable()
        // user approved permission
      } catch (error) {
        // user rejected permission
        console.log('user rejected permission')
      }
    }
    // No web3 provider
    else {
      console.log('No web3 provider detected')
    }

    try {

      // Get connected chain id from Ethereum node
      const chainId = await Wallet.web3.eth.getChainId()
      // Load chain information over an HTTP API
      const chainData = await evmChains.getChain(chainId)
      //console.log(chainData.name)

      if (chainId != 137) {
        if (await Wallet.addPolygonTestnetNetwork()) {
          const accounts = await Wallet.web3.eth.getAccounts()

          // MetaMask does not give you all accounts, only the selected account
          console.log('Got accounts', accounts)
          selectedAccount = accounts[0]

          Wallet.address = selectedAccount

          return true
        } else window.alert('Connect to Matic Polygon Network')
      }

    }catch(e){}

    try {
      // Get list of accounts of the connected wallet
      const accounts = await Wallet.web3.eth.getAccounts()

      // MetaMask does not give you all accounts, only the selected account
      selectedAccount = accounts[0]

      Wallet.address = selectedAccount

    }catch(e){}


    return Wallet.address ? true : false
  },

  addPolygonTestnetNetwork: async () => {
    let _chain = await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x89', // Hexadecimal version of 137, prefixed with 0x
          chainName: 'POLYGON MATIC',
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
          },
          rpcUrls: ['https://polygon-rpc.com/'],
          blockExplorerUrls: ['https://polygonscan.com/'],
          iconUrls: [''],
        },
      ],
    })

    if (_chain) return true

    _chain = await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x89' }], // Hexadecimal version of 80001, prefixed with 0x
    })
    if (_chain) return true

    return false
  },

  /**
   * Connect wallet button pressed.
   */
  onConnect: async () => {
    //localStorage.setItem("provider", provider)

    if (provider != false) {
      provider = ((provider != false) ? provider : (window.web3 ? window.web3 : window.ethereum))

      Wallet.web3 = new Web3(provider)
      
      console.log('Wallet.web3', Wallet.web3)

      return (provider != false) ? await Wallet.fetchAccountData() : false
    } else if (Wallet.web3) {
      return true
    }

    return false
  },

  /**
   * Disconnect wallet button pressed.
   */
  onDisconnect: async () => {
    try {
      //walletLink.disconnect()
      //ethereum.close()
    } catch (e) {}

    try {
      if(provider.close) {
        await provider.close();
        await web3Modal.clearCachedProvider();
        provider = null
        selectedAccount = null
        Wallet.address = null
        Wallet.web3 = null
      }
    } catch (e) {}

    return false
  },

  /**
   * Main entry point.
   */

  connectWallet: async () => {
    if (Wallet.address) {
      let _t = true;
      try{_t = await Wallet.onDisconnect()}catch(e){}
      return _t;
    } else {
      try{await Wallet.init()}catch(e){}
      let _t = false;
      try{_t = await Wallet.onConnect();}catch(e){}
      return _t;
    }
  },

  CONTRACTS: () => {
    return require('./data/currentContracts.json')
  },
  timeAddress: () => {
    return Wallet.CONTRACTS().timeAddress
  },
  ticketAddress: () => {
    return Wallet.CONTRACTS().ticketAddress
  },
  guessAddress: () => {
    return Wallet.CONTRACTS().guessAddress
  },
  createAddress: () => {
    return Wallet.CONTRACTS().createAddress
  },
  gameXAddress: () => {
    return Wallet.CONTRACTS().gameXaddress
  },

  timeContract: () => {
    return new Wallet.web3.eth.Contract(
      require('./data/abi/Time.json'),
      Wallet.timeAddress()
    )
  },

  ticketContract: () => {
    return new Wallet.web3.eth.Contract(
      require('./data/abi/Ticket.json'),
      Wallet.ticketAddress(),
      {
        from: Wallet.address, // default from address
      }
    )
  },

  guessContract: () => {
    return new Wallet.web3.eth.Contract(
      require('./data/abi/Guess.json'),
      Wallet.guessAddress(),
      {
        from: Wallet.address, // default from address
      }
    )
  },

  createContract: () => {
    return new Wallet.web3.eth.Contract(
      require('./data/abi/Create.json'),
      Wallet.createAddress(),
      {
        from: Wallet.address, // default from address
      }
    )
  },

  gameXContract: () => {
    return new Wallet.web3.eth.Contract(
      require('./data/abi/gameX.json'),
      Wallet.gameXAddress(),
      {
        from: Wallet.address, // default from address
      }
    )
  },

  theTokenLeft: async () => {
    try{return await Wallet.ticketContract()
      .methods.balanceOf(Wallet.address.toString().toLocaleLowerCase())
      .call({
        gas: 0,
        gasPrice: 0,
        method: 'requestAccounts',
        params: [],
        from: Wallet.address,
        to: Wallet.ticketAddress(),
      })}catch(e){}
  },

  theTimeLeft: async () => {
    try{return await Wallet.timeContract()
      .methods.balanceOf(Wallet.address.toString().toLocaleLowerCase())
      .call({
        gas: 0,
        gasPrice: 0,
        method: 'requestAccounts',
        params: [],
        from: Wallet.address,
        to: Wallet.timeAddress(),
      })}catch(e){}
  },
  copy: (_address) => {
    const el = document.createElement("input");
    el.value = _address;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    window.alert("Copied: " + _address);
  }
}

export default Wallet
