import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import NftInWallet from "../NftInWallet";
import "./Nfts.css";


import Wallet from "../../interface";

const fetch = require('node-fetch');

const HOST = process.env.HOST || "giftaguess.com";
const PORT = process.env.PORT || "";

function Nfts(props) {

  const [_list, _setList] = useState([]);

  let items = [];
  const [_item, _setItem] = useState([]);


  useEffect(async () => {
    if (localStorage.getItem("_gift_guess_2") == null) { 
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Connect Wallet',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("Connect Wallet"); 
      location.href = "/"; 
    }
    else {
      Wallet.web3 = localStorage.getItem("_gift_guess_2"); await Wallet.fetchAccountData();

      let response = await (await fetch('https://' + HOST + '' + PORT + '/nftlist/' + Wallet.address)).json();
      _setList(response);

      response = Object.entries(response);

      let _i = 0
      for (let i = 0; i < response.length; i++) {
        for (let j = 0; j < response[i][1].list.length; j++) {
          let uri = (response[i][1].list[j].uri).replaceAll("ipfs://", "");
          try {

            let _uri = await (await fetch("https://ipfs.io/ipfs/" + uri)).json();

            items.push({
              _key: _i,
              id: response[i][1].list[j].id,
              address: response[i][0],
              title: response[i][1].symbol,
              description: _uri.name,
              img: "https://ipfs.io/ipfs/" + _uri.image.replaceAll("ipfs://", "")
            });
          } catch (e) {
            items.push({
              _key: _i,
              id: response[i][1].list[j].id,
              address: response[i][0],
              title: null,
              description: null,
              img: null
            });
            _setItem(items);
          }
          _i++;
        }
      }
      _setListStyleHeigh(300 + (_i) * 310);
      _setItem(items);
    }
  }, []);

  const [_listStyleHeigh, _setListStyleHeigh] = useState(0);

  const { createNftBtn, closeNfts } = props;

  return (
    <div className="nfts screen" style={{ height: `${_listStyleHeigh}px` }} >
      <div className="nfts-boxed">
        <div className="nft-container">
          <div className="loading-nfts helveticaneue-condensed-black-gravel-28px">{_item.length == 0 ? (<div class="loading_">Loading&#8230;</div>) : ""}</div>
          <Link to="/create">
            <img className="create-nft-btn" src={createNftBtn} />
          </Link>
        </div>
        {_item.map(({
          _key,
          id,
          address,
          title,
          description,
          img
        }) => (
          <Link to={`/giftaguess/nft/${id}/${address}`}>
            <NftInWallet key={_key + address} id={id} address={address} title={title} description={description} img={img} />
          </Link>
        ))}
      </div>
      <Link to="/giftaguess/nft/0/0x0000">
        <img className="close-nfts" src={closeNfts} />
      </Link>
    </div>
  );
}

export default Nfts;
