import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Swap.css";

import Transaction from "../Transaction";

import Wallet from "../../interface";

function Swap(props) {

  useEffect(async () => {
    if (localStorage.getItem("_gift_guess_2") == null) { 
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Connect Wallet',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("Connect Wallet"); 
      location.href = "/"; 
    }
    else {
      Wallet.web3 = localStorage.getItem("_gift_guess_2"); await Wallet.fetchAccountData();
    }
  }, []);

  const [_timeLeft, _getTimeLeft] = useState(0);

  const [_amount, setAmount] = useState(0);

  let val = 0;
  const updateAmount = (evt) => {
    val = evt.target.value;
    setAmount(val);// % 60);
  }

  const swapForTicket = async () => {
    

    window.Swal.fire({
      position: 'top-end',
      //icon: 'error',
      title: 'Please sign the next 2 consecutive transactions to allow the contract transfer the asset to other players when a letter is guessed wrong',
      showConfirmButton: false,
      timer: 5000
    })

    //alert("Please sign the next 2 consecutive transactions to allow the contract transfer the asset to other players when a letter is guessed wrong");

    let amount = _amount * 60;
    await Wallet.gameXContract().methods.swapForTicket(amount)
      .send({
        from: Wallet.address,
        to: Wallet.gameXAddress(),
      });


    await new Wallet.web3.eth.Contract(require("../../data/abi/Erc20.json"), Wallet.ticketAddress(), {
      from: Wallet.address, // default from address
    }).methods.approve(Wallet.guessAddress(), amount).send({ from: Wallet.address });

    window.location.href = "/game";

  }

  const [activeTransaction, showTransaction] = useState(false)
  const emitTransaction = () => { swapForTicket(); /*showTransaction(true)*/ }
  const hideTransaction = () => activeTransaction ? showTransaction(false) : null;

  const {
    closeSwap,
    path121,
    path131,
    path141,
    path151,
    path161,
    path171,
    path181,
    path191,
    iconAwesomeDice0,
    inputType1,
    overlapGroup,
    path122,
    path132,
    path142,
    path152,
    path162,
    path172,
    path182,
    path192,
    iconAwesomeDice,
    inputType2,
    inputPlaceholder2,
    swapBtn,
  } = props;

  return (
    <div className="swap screen" onClick={hideTransaction}>

      {activeTransaction ? <Transaction /> : null}

      <div className="swap-boxed">
        <Link to="/game/0">
          <img className="close-swap" src={closeSwap} />
        </Link>
      </div>
      <div className="frame-frame-swap">
        <div className="flex-col-1 border-5px-dove-gray">
          <div className="flex-row-4">
            <div className="overlap-group1-6">
              {/*<div className="ellipse-2-7"></div>
              <img className="path-12-8" src={path121} />
              <img className="path-13-8" src={path131} />
              <img className="path-14-8" src={path141} />
              <img className="path-15-8" src={path151} />
              <img className="path-16-8" src={path161} />
              <img className="path-17-8" src={path171} />
              <img className="path-18-8" src={path181} />
              <img className="path-19-8" src={path191} />*/}
              <img className="icon-awesome-dice-0" src={"/img/time_logo_0.jpg"} />
            </div>
            <textarea
              className="time-amount-to-swap helveticaneue-condensed-black-dove-gray-54px"
              name="time-amount-to-swap3"
              placeholder={"0 min"}
              type={"number"}
              step={"any"}
              required
              onKeyDown={(e)=>{if(e.key === "Enter"){document.activeElement.blur();emitTransaction()}}}
              onChange={evt => updateAmount(evt)}
            ></textarea>
          </div>
          <div className="flex-row-5">
            <div className="overlap-group-6" >
              {/*<div className="ellipse-2-8"></div>
              <img className="path-12-9" src={path122} />
              <img className="path-13-9" src={path132} />
              <img className="path-14-9" src={path142} />
              <img className="path-15-9" src={path152} />
              <img className="path-16-9" src={path162} />
              <img className="path-17-9" src={path172} />
              <img className="path-18-9" src={path182} />
              <img className="path-19-9" src={path192} />*/}
              <img className="icon-awesome-dice-0" src={"/img/ticket_logo_0.jpg"} />
            </div>
            <div
              className="tickets-amount-swap helveticaneue-condensed-black-dove-gray-62px"
              name="tickets-amount-swap1"
              placeholder={inputPlaceholder2}
              type={inputType2}
              required
            >{_amount}</div>
          </div>
        </div>
        <a href="swap#transaction" onClick={emitTransaction} >
          <img className="swap-btn push_btn" src={"/img/push_btn.png"} />
        </a>
      </div>
    </div>
  );
}

export default Swap;
