import React from "react";
import "./TokenFrame.css";

import Wallet from "../../interface";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function TokenFrame(props) {
  const { className, _key, value, symbol, address } = props;

  const [_tokenImage, setTokenImage] = React.useState("/img/tokens_btn.jpg");


  let _time = 0;
  const _value = async () => {
    if(Wallet.timeAddress().toLowerCase() == address.toLowerCase()){

      setTokenImage("/img/time_logo_0.jpg");

      _time = await Wallet.theTimeLeft();

      let s = _time % 60;
      let m = parseInt(_time / 60) % 60;
      let h = parseInt(parseInt(_time / 60) / 60) % 24;

      if (s.toString().length < 2) { s = "0" + s; }
      if (m.toString().length < 2) { m = "0" + m; }
      if (h.toString().length < 2) { h = "0" + h; }

      return value ? ((_time == null || _time == 0) ? "NO TIME" : (h == '00' ? '' : (h + ":")) + m + ":" + s) : "0";
    }
    else{
      let decimals = 10**18;
      try{decimals = (await new Wallet.web3.eth.Contract(require("../../data/abi/Erc20.json"), address, {
        from: Wallet.address,
        to: address,
     }).methods.decimals().call({ "from": Wallet.address, "to": address }));}catch(e){}

      return value ? 
      ((Wallet.ticketAddress().toLowerCase() == address.toLowerCase())?()=>
      {setTokenImage("/img/ticket_logo_0.jpg"); return (value*10**18).toString().substring(0, 12)}
      :(value*decimals).toString().substring(0, 12)) : "0";
    }
  }

  const [_value_, _setValue] = React.useState(value);

  React.useEffect(async () => {
    _setValue(await _value());


    if(Wallet.timeAddress().toLowerCase() == address.toLowerCase()){
      
      while(_time >= 0){

        let s = _time % 60;
        let m = parseInt(_time / 60) % 60;
        let h = parseInt(parseInt(_time / 60) / 60) % 24;

        if (s.toString().length < 2) { s = "0" + s; }
        if (m.toString().length < 2) { m = "0" + m; }
        if (h.toString().length < 2) { h = "0" + h; }
        
        await sleep(1000);
        _setValue((_time == null || _time == 0) ? "NO TIME" : (h == '00' ? '' : (h + ":")) + m + ":" + s);
        _time -= 1;

      }
    }

  }, []);

  return (
    <div id={`L_${_key}`} className={`token-frame border-5px-dove-gray ${className || ""}`} style={{marginBottom: "20px"}}>
      <img className="in-wallet-token-image" src={_tokenImage} />
      <div className="in-wallet-token-container">
        <div className="in-wallet-token-address helveticaneue-condensed-black-gravel-28px pointer-cursor" onClick={()=>Wallet.copy(address)} >{address ? address.substring(0, 5) + '...' + address.substring(address.length - 4) : ""}</div>
        <div className="in-wallet-token-amount helveticaneue-condensed-black-gravel-28px">{_value_}</div>
        <div className="in-wallet-token-name helveticaneue-condensed-black-gravel-32px">{symbol ? symbol.substring(0, 8) : ""}</div>
      </div>
    </div>
  );
}
//
export default TokenFrame;

