import React from "react";

import "./NftInWallet.css";

import Wallet from "../../interface"


function NftInWallet(props) {
  const { className, _key, id, address, title, description, img } = props;

  return (
    <div id={`L_${_key}`} className={`nft-in-wallet border-5px-dove-gray ${className || ""}`}>
      <div className="flex-row-3">
        <img className="nft-in-wallet-image" src={img ? img : ""} />
        <div className="nft-in-wallet-container helveticaneue-condensed-black-gravel-28px">
          <div className="nft-in-wallet-address pointer-cursor" onClick={()=>Wallet.copy(address)}>{address ? address.substring(0, 5) + '...' + address.substring(address.length - 4)  : ""}</div>
          <div className="nft-in-wallet-id">{id ? id.toString().substring(0, 12) : "0"}</div>
        </div>
      </div>
      <div className="nft-in-wallet-container-1 helveticaneue-condensed-black-gravel-32px">
        <div className="nft-in-wallet-title">{title ? title.substring(0, 100) : ""}</div>
        <div className="nft-in-wallet-description">{description ? description.substring(0, 500) : ""}</div>
      </div>
    </div>
  );
}

export default NftInWallet;
