import React from "react";
import { Link, useParams } from "react-router-dom";
import "./Info.css";

import Wallet from "../../interface";

function Info(props) {

  let { id } = useParams();

  React.useEffect(async () => {
    if (localStorage.getItem("_gift_guess_2") == null) { 
      
      window.Swal.fire({
        position: 'top-end',
        //icon: 'error',
        title: 'Connect Wallet',
        showConfirmButton: false,
        timer: 5000
      })

      //window.alert("Connect Wallet"); 
      location.href = "/"; 
    }
    else {
      Wallet.web3 = localStorage.getItem("_gift_guess_2"); await Wallet.fetchAccountData();

      if (id > 0) {
        await emitLoad(id);
        _setList(list);
      }
      else { window.alert("Roll the dice to fetch a random guess info"); location.href = "/game"; }

    }
  }, []);


  let list = [];
  const [_list, _setList] = React.useState([]);

  const emitLoad = async (_i) => {

    if (await Wallet.guessContract().methods.isGuessAvailable(_i)
      .call({ "from": Wallet.address, "to": Wallet.guessAddress() })) {

      try {

        let _guessId = (_i);

        let _amountToken = (await Wallet.guessContract().methods.getGuessWinTokenAmount(_i)
          .call({ "from": Wallet.address, "to": Wallet.guessAddress() }));

        let _addressToken = (await Wallet.guessContract().methods.getGuessWinTokenAddress(_i)
          .call({ "from": Wallet.address, "to": Wallet.guessAddress() }));

        let _tokenSymbol =
          await new Wallet.web3.eth.Contract(require("../../data/abi/Erc20.json"), _addressToken, {
            from: Wallet.address, // default from address
          })
            .methods.symbol().call({ from: Wallet.address });


        let _idNft = (await Wallet.guessContract().methods.getGuessWinNftId(_i)
          .call({ "from": Wallet.address, "to": Wallet.guessAddress() }));

        let _addressNft = (await Wallet.guessContract().methods.getGuessWinNftAddress(_i)
          .call({ "from": Wallet.address, "to": Wallet.guessAddress() }));

        let _uriNft =
          await new Wallet.web3.eth.Contract(require("../../data/abi/Erc721.json"), _addressNft, {
            from: Wallet.address, // default from address
          })
            .methods.tokenURI(_idNft).call({ from: Wallet.address });

        _uriNft = _uriNft.replaceAll("ipfs://", "");
        let _uri; try { _uri = await (await fetch("https://ipfs.io/ipfs/" + _uriNft)).json(); } catch (e) { }

        let _nftSymbol =
          await new Wallet.web3.eth.Contract(require("../../data/abi/Erc721.json"), _addressNft, {
            from: Wallet.address, // default from address
          })
            .methods.symbol().call({ from: Wallet.address });

        let _guessLetterCost = (await Wallet.guessContract().methods.getGuessTicketCost(_i)
          .call({ "from": Wallet.address, "to": Wallet.guessAddress() }));

        list.push({
          guessId: _guessId,
          tokenSymbol: _tokenSymbol,
          amountToken: _amountToken,
          addressToken: _addressToken,
          nftSymbol: _nftSymbol,
          idNft: _idNft,
          addressNft: _addressNft,
          uri: _uri,
          guessLetterCost: _guessLetterCost
        });
      }
      catch (e) {
        console.log(e);
      }
    }
  }


  const {
    closeInfo,
    frameBottomGuess,
    overlapGroup,
    path12,
    path13,
    path14,
    path15,
    path16,
    path17,
    path18,
    path19,
    iconAwesomeDice,
    guessContainer,
    tokenFrameGuess,
    tokenImageRewardGuess,

    nftFrameFrameGuess
  } = props;

  return (
    <>
      {_list.map(({
        guessId,
        tokenSymbol,
        amountToken,
        addressToken,
        nftSymbol,
        idNft,
        addressNft,
        uri,
        guessLetterCost
      }) => (
          <div className="info-1 screen">
            <Link to={`/game/${0}`}>
              <img className="close-info" src={closeInfo} />
            </Link>

            <div className="guess guess-info-0" key={guessId+addressToken+addressNft} >

              <div className="guess-container">
                <img className="nft-frame-frame-guess" src={nftFrameFrameGuess} />
                <div className="nft-address-guess helveticaneue-condensed-black-gravel-28px pointer-cursor" onClick={() => Wallet.copy(addressNft)} >{addressNft.substring(0, 5) + '...' + addressNft.substring(addressNft.length - 4)}</div>
                <div className="nft-id-guess helveticaneue-condensed-black-gravel-28px">{idNft.substring(0, 18)}</div>
                <div className="nft-title-guess helveticaneue-condensed-black-gravel-32px">{nftSymbol}</div>
                <div className="nft-description-guess helveticaneue-condensed-black-gravel-32px">
                  {uri ? uri.name.substring(0, 100) : ""}
                </div>
                <img className="nft-image-guess" src={uri ? "https://ipfs.io/ipfs/" + uri.image.replaceAll("ipfs://", "") : ""} />
              </div>
              <img className="frame-bottom-guess" src={frameBottomGuess} />
              <div className="guess-container-1" >
                <img className="token-frame-guess" src={tokenFrameGuess} />
                <img className="token-image-reward-guess" src={(Wallet.ticketAddress().toLowerCase() == addressToken.toLowerCase())?"/img/ticket_logo_0.jpg":"/img/tokens_btn.jpg"} />
                <div className="token-address-reward-guess helveticaneue-condensed-black-gravel-28px pointer-cursor" onClick={() => Wallet.copy(addressToken)} >
                  {addressToken.substring(0, 5) + '...' + addressToken.substring(addressToken.length - 4)}
                </div>
                <div className="token-amount-reward-guess helveticaneue-condensed-black-gravel-28px">
                  {amountToken ? ((Wallet.ticketAddress().toLowerCase() == addressToken.toLowerCase()) ? (amountToken).toString().substring(0, 12) : (amountToken / (10 ** 18)).toString().substring(0, 12)) : "0"}
                </div>
                <div className="token-name-reward-guess helveticaneue-condensed-black-gravel-32px">
                  {tokenSymbol}
                </div>
              </div>
              <div className="word-guess-ticket-cost">
                <div className="tickets-cost-icon">
                  <div className="overlap-group">
                    <img className="icon-awesome-dice-0" src={"/img/ticket_logo_0.jpg"} />
                  </div>
                </div>
                <div className="ticket-amount-guess helveticaneue-condensed-black-gravel-48px">{guessLetterCost}</div>
              </div>

            </div>
          </div>

      ))}
    </>
  );
}

export default Info;
